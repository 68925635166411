// //-------------------------------------//
// // COLORS

$neutrals-800: #262626;
$orange-900: #FD5F05;

// //-------------------------------------//
// // FONTS

// // fonts: base styles
$font-family-1: 'Roboto', 'Inter', 'Noto Sans Myanmar';
