@import '../utils/_variables';

html,
body {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-family: $font-family-1;
    line-height: 24px;
    color: $neutrals-800;
}

body {
    position: relative;
}

#outer-container {
    min-height: 100vh;
}

// //-------------------------------------//
// // MUI-based general

.MuiOutlinedInput-root.Mui-focused
.MuiOutlinedInput-notchedOutline {
  border-width: 1px;
}

.MuiInputBase-input {
    font-size: 14px !important;
}

.view {
    font-weight: 600;
    text-decoration: underline;
}
