
@import '../../../styles/utils/_variables';
@import '../../../styles/utils/_mixins';

.mui-navbar {

}

#language-select {
  width: 65px;
}